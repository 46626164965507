<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import api from '@/aws/api'
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    extraField: {}
  },

  data () {
    return {
      selected: this.value,
      options: []
    }
  },

  mounted () {
    this.loadOptions()
  },

  methods: {
    loadOptions () {
      const vue = this
      const documentTypeId = vue.extraField
      const params = documentTypeId ? { documentTypeId } : {}
      params.limit = 999999

      api.getDocumentSubTypes(params).then(({ rows }) => {
        vue.options = rows.map(toOption)
      })
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    },

    extraField () {
      const vue = this
      vue.options = []
      vue.$nextTick().then(() => vue.loadOptions())
    }
  }
}

const toOption = (subType) => {
  const option = {
    id: subType.id,
    label: subType.name
  }

  if (option.label.includes(' - ')) {
    const slices = option.label.split(' - ')
    slices.shift()
    option.label = slices.join(' - ')
  }

  return option
}

</script>
