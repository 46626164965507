<template>
  <FieldMultiSelect
    :label="label"
    :state="state"
    :disabled="disabled"
    :is-required="isRequired"
    :class-label="classLabel"
    option-description="prefix"

    v-model="selected"
    :placeholder="placeholder"
    :load-options="loadOptions"
  />
</template>

<script>
import api from '@/aws/api'
import FieldMultiSelect from '@/components/forms/FieldMultiSelect'

export default {
  components: {
    FieldMultiSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    extraField: {}
  },

  data () {
    return {
      selected: toSelected(this.value)
    }
  },

  computed: {
    placeholder () {
      const hasValue = Array.isArray(this.selected) && this.selected.length
      return hasValue ? '' : 'Selecionar'
    },

    scope () {
      return String(this.extraField || '').toUpperCase().trim()
    }
  },

  methods: {
    loadOptions () {
      const limit = 999999
      return api.getBoardCouncil({ limit }).then(({ rows }) => {
        if (['R', 'L'].includes(this.scope)) rows = rows.filter(({ id }) => id === 5)
        return rows
      })
    }
  },

  watch: {
    selected (selected) {
      if (isEqual(selected, this.value)) return
      this.$emit('input', toSelected(selected))
    },

    value (value) {
      if (isEqual(this.selected, value)) return
      this.selected = toSelected(value)
    }
  }
}

const toSelected = (val) => {
  if (Array.isArray(val)) return [...val]
  return val ? [val] : null
}

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>
