<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Fuso Horários'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      selected: this.value,
      options: [
        { id: '-04:00', label: '(GMT -04:00) Brasil (Cuiabá), Atlantic Time (Canada),  Caracas, La Paz' },
        { id: '-03:00', label: '(GMT -03:00) Brasil (Brasilia), Buenos Aires, Georgetown' },
        { id: '-02:00', label: '(GMT -02:00) Mid-Atlantic' },
        { id: '+01:00', label: '(GMT +01:00) Brussels, Copenhagen, Madrid, Paris' },
        { id: '-01:00', label: '(GMT -01:00) Azores, Cape Verde Islands' },
        { id: '-12:00', label: '(GMT -12:00) Eniwetok, Kwajalein' },
        { id: '-11:00', label: '(GMT -11:00) Midway Island, Samoa' },
        { id: '-10:00', label: '(GMT -10:00) Hawaii' },
        { id: '-09:00', label: '(GMT -09:00) Alaska' },
        { id: '-08:00', label: '(GMT -08:00) Pacific Time (US & Canada)' },
        { id: '-07:00', label: '(GMT -07:00) Mountain Time (US & Canada)' },
        { id: '-06:00', label: '(GMT -06:00) Central Time (US & Canada), Mexico City' },
        { id: '-05:00', label: '(GMT -05:00) Eastern Time (US & Canada), Bogota, Lima' },
        { id: '00:00', label: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
        { id: '+02:00', label: '(GMT +02:00) Kaliningrad, South Africa' },
        { id: '+03:00', label: '(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
        { id: '+04:00', label: '(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
        { id: '+05:00', label: '(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
        { id: '+06:00', label: '(GMT +06:00) Almaty, Dhaka, Colombo' },
        { id: '+07:00', label: '(GMT +07:00) Bangkok, Hanoi, Jakarta' },
        { id: '+08:00', label: '(GMT +08:00) Beijing, Perth, Singapore, Hong Kong' },
        { id: '+09:00', label: '(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
        { id: '+10:00', label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
        { id: '+11:00', label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
        { id: '+12:00', label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' }
      ]
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

</script>
