<template>
  <div :class="panel.class">
    <div class="column">
      <div
        v-if="!hideLabel && panel.label"
        class="col-auto q-pb-xs"
      >
        <label
          class="panelLabel q-px-sm"
          v-if="panelLabel"
        >
          {{ panelLabel }}
        </label>
      </div>

      <div class="col">
        <div
          v-if="fields.length"
          :class="`row full-width ${panel.justifyItems || 'justify-start'}`"
        >
          <template v-for="field in fields">
            <CollapseTransition :key="`${field.name}-${field.isVisible}`">
              <div
                :class="[loadProp(field.class, input), '', field.padding || 'q-pa-sm']"
                v-if="field.isVisible"
              >
                <component
                  :disabled="[false, undefined, null].includes(field.checkDisabled) ? field.disabled : field.checkDisabled(input) "
                  v-model="input[field.name]"
                  :is="field.component"
                  :label="loadProp(field.label, input)"
                  :is-required="loadProp(field.isRequired, input)"
                  :class-label="loadProp(field.classLabel, input)"
                  :accept="field.accept"
                  :placeholder="field.placeholder"
                  :maxlength="field.maxlength"
                  :prefix="field.prefix"
                  :suffix="field.suffix"
                  :state="getState(field.name, input)"
                  :extra-field.sync="input[field.extraField]"
                  :extra-field2.sync="input[field.extraField2]"
                />
              </div>
            </CollapseTransition>
          </template>
        </div>

        <div
          v-if="hasHorizontalSteps"
          class="row full-width form-render-tabs"
        >
          <div class="col bg-white">
            <q-tabs
              v-model="tab"
              dense
              align="justify"
              narrow-indicator
              active-color="primary"
              indicator-color="primary"
            >
              <template v-for="(step, indexStep) in steps">
                <q-tab
                  :key="indexStep"
                  :name="indexStep"
                  :label="step.label"
                  :class="hasError(step) ? 'text-red-8' : 'text-grey'"
                />
              </template>
            </q-tabs>

            <q-separator />

            <q-tab-panels
              v-model="tab"
              animated
            >
              <template v-for="(step, indexStep) in steps">
                <q-tab-panel
                  :key="indexStep"
                  :name="indexStep"
                >
                  <div class="row full-width">
                    <FormPanel
                      :key="indexStep"
                      :panel="step.form"
                      v-model="input"
                    />
                  </div>
                </q-tab-panel>
              </template>
            </q-tab-panels>
          </div>
        </div>

        <div
          v-if="hasVerticalSteps && panelLabel"
          class="row full-width"
        >
          <div class="col">
            <hr style="border-top: 0; border-bottom: 1px solid rgb(207, 216, 220);">
          </div>
        </div>

        <q-splitter
          v-if="hasVerticalSteps"
          v-model="splitterModel"
        >
          <template #before>
            <q-tabs
              dense
              vertical
              active-color="primary"
              indicator-color="primary"
              v-model="tab"
            >
              <template v-for="(step, indexStep) in steps">
                <q-tab
                  no-caps

                  :key="indexStep"
                  :name="indexStep"
                  :icon="step.icon"
                  :label="step.label"
                  :class="hasError(step) ? 'divider-tab text-red-8' : 'divider-tab'"
                />
              </template>
            </q-tabs>
          </template>

          <template #after>
            <q-tab-panels
              v-model="tab"
              animated
              vertical
              transition-prev="jump-up"
              transition-next="jump-down"
            >
              <template v-for="(step, indexStep) in steps">
                <q-tab-panel
                  :key="indexStep"
                  :name="indexStep"
                >
                  <FormPanel
                    :panel="steps[tab].form"
                    v-model="input"
                  />
                </q-tab-panel>
              </template>
            </q-tab-panels>
          </template>
        </q-splitter>

        <div
          v-if="panels.length"
          class="row full-width"
        >
          <template v-for="(subPanel, index) in panels">
            <FormPanel
              :key="index"
              v-model="input"
              :panel="subPanel"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from './reduceFields'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

export default {
  name: 'FormPanel',

  components: {
    CollapseTransition
  },

  props: {
    hideLabel: {
      type: Boolean,
      required: false
    },

    panel: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    }
  },

  data () {
    const allFields = mapFields(this.panel)
    const splitterModel = 20

    return {
      tab: 0,
      allFields,
      splitterModel,
      input: inputFields(allFields, this.value)
    }
  },

  computed: {
    panelLabel () {
      const vm = this
      return vm.loadProp(vm.panel.label, vm.input)
    },

    fields () {
      const fields = this.panel && Array.isArray(this.panel.fields) ? this.panel.fields : []

      return fields.map((field) => {
        let isVisible = [true, undefined, null].includes(field.checkVisible)
        if (typeof field.checkVisible === 'function') isVisible = field.checkVisible(this.input)
        return { ...field, isVisible }
      })
    },

    panels () {
      return this.panel && Array.isArray(this.panel.panels) ? this.panel.panels : []
    },

    steps () {
      return this.panel && Array.isArray(this.panel.steps) ? this.panel.steps : []
    },

    hasVerticalSteps () {
      return this.steps.length && [null, undefined, false, 0, ''].includes(this.panel.isHorizontal)
    },

    hasHorizontalSteps () {
      return this.steps.length && !this.hasVerticalSteps
    }
  },

  methods: {
    hasError ({ form }) {
      const vm = this
      const fields = Object.keys(mapFields(form))
      const state = vm.value?.$state || {}

      for (const fieldName of fields) {
        if (state[fieldName]?.error) {
          return true
        }
      }

      return false
    },

    getState (fieldName, input) {
      const state = input.$state ?? {}
      return state[fieldName]
    },

    loadProp (prop, input) {
      const isFunc = typeof prop === 'function'
      return isFunc ? prop(input) : prop
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        const input = inputFields(this.allFields, value)
        if (!isEqual(this.input, input)) this.input = input
      }
    },

    input: {
      deep: true,
      handler (input) {
        const value = clone(this.value)
        Object.keys(this.allFields).forEach((name) => Object.assign(value, { [name]: input[name] }))
        if (!isEqual(this.value, value)) this.$emit('input', value)
      }
    }
  }
}

const isEqual = (target, source) => JSON.stringify(target) === JSON.stringify(source)
const clone = (val) => JSON.parse(JSON.stringify(val))

const inputFields = (allFields, value) => {
  const input = typeof value === 'object' ? value : { $state: {} }
  const fields = Object.keys(allFields)
  const $state = fields.reduce((acc, name) => ({ ...acc, [name]: getState(input.$state, name) }), {})
  return fields.reduce((acc, name) => ({ ...acc, [name]: input[name] === undefined ? null : input[name] }), { $state })
}

const getState = (state, name) => state && state[name] ? state[name] : { dirty: false, error: null }

</script>

<style lang="sass" scoped>
  .panelLabel
    font-style: normal
    font-weight: bold
    font-size: 1em
    line-height: 19px
    color: #212121

  .divider-tab
    border-bottom: 1px solid #ddd
</style>
