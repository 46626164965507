import api from '@/aws/api'

export default async (file, isPublic, onProgress) => {
  const { name, size, type: mime, source } = file
  const signData = { name, mime, size, source, isPublic }

  return api.getSignedPostUrl(signData).then((signedParams) => (
    uploadFile(file, signedParams, onProgress)
  ))
}

const uploadFile = (file, signedParams, onProgress) => {
  const formData = new FormData()
  Object.entries(signedParams.fields).forEach(([k, v]) => formData.append(k, v))
  formData.append('file', file)

  const xhr = new XMLHttpRequest()
  xhr.responseType = 'document'
  xhr.withCredentials = false

  xhr.upload.addEventListener('progress', (e) => {
    if (!e.lengthComputable) return

    const total = e.total || 0
    const loaded = e.loaded || 0
    const perc = total ? loaded / total : 0
    onProgress(perc, loaded, total)
  })

  return new Promise((resolve, reject) => {
    xhr.addEventListener('error', reject)
    xhr.addEventListener('abort', reject)

    xhr.addEventListener('load', () => {
      const res = xhr.response
      if (!res) return reject(new Error('No Response'))

      if (res.querySelector('Error')) {
        const reason = res.querySelector('Code').textContent + ': ' + res.querySelector('Message').textContent
        return reject(new Error(reason))
      }

      const { id } = signedParams
      const url = res.querySelector('Location').textContent

      return url
        ? resolve({ id, url, default: url })
        : reject(new Error('NoLocation: No location in s3 POST response'))
    })

    xhr.open('POST', signedParams.url, true)
    xhr.send(formData)
  })
}
