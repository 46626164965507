<template>
  <FieldWrapper
    :label="label"
    :state="state"
    :is-required="isRequired"
    :class-label="classLabel"
    @on-click="openCalendar()"
  >
    <template #after-label>
      <FieldInput
        :value="value"
        :state="state"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="text"
        readonly
      >
        <template #append>
          <q-btn
            dense
            flat
            size="0.5em"
            padding="0em"
            icon="mdi-calendar-month-outline"
          />
        </template>
      </FieldInput>
    </template>
  </FieldWrapper>
</template>

<script>
import moment from '@/components/moment'
import FieldInput from '@/components/forms/FieldInput'
import FieldWrapper from '@/components/forms/FieldWrapper'
import ModalCalendar from '@/modules/schedules/components/ModalCalendar'
import log from '@/utils/log'

export default {
  components: {
    FieldInput,
    FieldWrapper
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      text: toDateTime(this.value)
    }
  },

  methods: {
    openCalendar () {
      if (this.disabled) return

      this.$q.dialog({ component: ModalCalendar }).onOk((timestamp) => {
        this.$emit('input', timestamp)
      }).onCancel(() => {
        log('onCancel')
      })
    }
  },

  watch: {
    value (timestamp) {
      const text = toDateTime(timestamp)
      if (this.text !== text) this.text = text
    }
  }
}

const toDateTime = (timestamp) => {
  if (!timestamp || isNaN(timestamp)) return ''
  const mDate = moment.unix(Number(timestamp))
  return mDate.isValid() ? mDate.tz('America/Sao_Paulo').format('DD/MM/YY HH:mm') : ''
}
</script>
