<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import api from '@/aws/api'
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Tipo de Documento'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      selected: this.value,
      options: []
    }
  },

  mounted () {
    const vue = this
    vue.loadOptions().then((options) => {
      vue.options = options
    })
  },

  methods: {
    loadOptions () {
      const limit = 999999
      return api.getDocumentTypes({ limit }).then(toOptions)
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

const toOptions = ({ rows }) => {
  const obj = rows.reduce((acc, item) => {
    acc[item.id] = item
    acc[item.id].label = String(item.name).normalize('NFD').trim()
    acc[item.id].children = []
    delete acc[item.id].name
    return acc
  }, {})

  for (const item of rows) {
    if (!item.parentId) continue
    obj[item.parentId].children.push(obj[item.id])
  }

  for (const item of rows) {
    if (item.parentId) delete obj[item.id]
  }

  return Object.values(obj)
}
</script>
