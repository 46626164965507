<template>
  <FieldWrapper
    :label="label"
    :state="state"
    :is-required="isRequired"
    :class-label="classLabel"
  >
    <FieldInput
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      v-model="text"
    >
      <template
        v-if="$scopedSlots['append']"
        #append="props"
      >
        <slot
          name="append"
          :props="props"
        />
      </template>
    </FieldInput>
  </FieldWrapper>
</template>

<script>
import FieldInput from '@/components/forms/FieldInput'
import FieldWrapper from '@/components/forms/FieldWrapper'

export default {
  components: {
    FieldInput,
    FieldWrapper
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    type: {
      type: String,
      default: 'text'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    maxlength: {
      type: Number,
      default: 255
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      text: forceStr(this.value)
    }
  },

  watch: {
    text (text) {
      if (isEqual(text, this.value)) return
      this.$emit('input', forceStr(text))
    },

    value (value) {
      if (isEqual(this.text, value)) return
      this.text = forceStr(value)
    }
  }
}

const forceStr = (val) => String(val || '')
const isEqual = (source, target) => forceStr(source) === forceStr(target)
</script>
