<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import api from '@/aws/api'
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Órgão Emissor'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      selected: this.value,
      options: []
    }
  },

  mounted () {
    const vue = this
    const limit = 999999
    vue.loadOptions({ limit }).then((options) => {
      vue.options = options
    })
  },

  methods: {
    loadOptions () {
      return api.getAgencies().then(toOptions)
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

const toOptions = ({ rows }) => (
  rows.map((agency) => ({
    id: agency.id,
    label: agency.name
  }))
)
</script>
