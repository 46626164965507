<template>
  <CkEditor
    :editor="editor"
    :config="editorConfig"
    v-model="editorData"
  />
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'

import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'

import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'

import TablePlugin from '@ckeditor/ckeditor5-table/src/table'
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar'

import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageResizePlugin from '@ckeditor/ckeditor5-image/src/imageresize'
import LinkImagePlugin from '@ckeditor/ckeditor5-link/src/linkimage'

import ImageBlockPlugin from '@ckeditor/ckeditor5-image/src/imageblock'
import ImageInlinePlugin from '@ckeditor/ckeditor5-image/src/imageinline'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import UploadS3Plugin from '@/utils/UploadS3Plugin'

import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'

import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import AutoLinkPlugin from '@ckeditor/ckeditor5-link/src/autolink'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlockPlugin from '@ckeditor/ckeditor5-indent/src/indentblock'

import FontPlugin from '@ckeditor/ckeditor5-font/src/font'
import ClipboardPlugin from '@ckeditor/ckeditor5-clipboard/src/clipboard'
import RemoveFormatPlugin from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
// import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed'

export default {
  components: {
    CkEditor: CKEditor.component
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    let editorData = forceStr(this.value)
    if (!editorData) editorData = '<p>Monte o Post de divulgação AQUI!</p>'

    return {
      editor: ClassicEditor,
      editorData,

      editorConfig: {
        plugins: [
          BoldPlugin,
          ItalicPlugin,
          UnderlinePlugin,

          FontPlugin,
          HeadingPlugin,

          AlignmentPlugin,
          ListPlugin,

          IndentPlugin,
          IndentBlockPlugin,

          LinkPlugin,
          AutoLinkPlugin,

          ParagraphPlugin,
          EssentialsPlugin,
          // MediaEmbedPlugin,

          ImagePlugin,
          ImageToolbarPlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageResizePlugin,
          LinkImagePlugin,
          ImageBlockPlugin,
          ImageInlinePlugin,
          ImageUploadPlugin,
          UploadS3Plugin,

          TablePlugin,
          TableToolbarPlugin,

          ClipboardPlugin,
          RemoveFormatPlugin,
          PasteFromOfficePlugin
        ],

        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            'heading',
            'fontfamily',
            '|',
            'fontsize',
            'bold',
            'italic',
            'underline',
            'alignment',
            'removeFormat',
            '|',
            'fontcolor',
            'fontBackgroundColor',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'link',
            'imageUpload',
            'insertTable'
            // 'mediaEmbed'
          ]
        },

        image: {
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'resizeImage',
            '|',
            'linkImage'
          ]
        },

        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },

        mediaEmbed: {}
      }
    }
  },

  watch: {
    editorData (editorData) {
      if (isEqual(editorData, this.value)) return
      this.$emit('input', forceStr(editorData))
    },

    value (value) {
      if (isEqual(this.editorData, value)) return
      this.editorData = forceStr(value)
    }
  }
}

const forceStr = (val) => String(val || '')
const isEqual = (source, target) => forceStr(source) === forceStr(target)
</script>
