<template>
  <div class="column full-width">
    <q-btn
      outline
      unelevated
      v-if="!readonly"
      color="blue-2"
      class="col-auto q-mx-sm"
      :disable="isCropping"
    >
      <label class="cursor-pointer">
        <AddAttachIcon />
        <q-tooltip>Clique aqui para Anexar arquivos ao documento</q-tooltip>
        <q-input
          type="file"
          class="hidden"
          v-model="vfiles"
          :multiple="true"
          @change="onFiles"
          :accept="accept"
          :disable="isCropping"
        />
      </label>
    </q-btn>

    <draggable
      v-if="!isCropping"
      :list="attachments"
      ghost-class="ghost"
      handle=".handle"
      class="col row q-px-xs"
    >
      <template v-for="(attach, index) in attachments">
        <FieldAttachment
          :attach="attach"
          :key="attach.id"
          :size="size"
          :readonly="readonly"
          @on-remove="attachments.splice(index, 1)"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import uploadS3 from '@/utils/uploadS3'
import AddAttachIcon from '@/assets/icons/AddAttachIcon.svg'
import FieldAttachment from './FieldAttachment'
import { toSlug } from '@/components/documents'

export default {
  components: {
    draggable,
    AddAttachIcon,
    FieldAttachment
  },

  props: {
    size: {},
    value: {},
    state: {},
    accept: {},

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const vm = this
    const vfiles = null
    let attachments = clone(vm.value)
    if (!Array.isArray(attachments)) attachments = []

    return {
      vfiles,
      isCropping: false,
      attachments
    }
  },

  beforeDestroy () {
    this.$root.$emit('closeCropArea')
  },

  methods: {
    openFiles () {
      this.$refs.file.pickFiles()
    },

    cropImage ({ resizes }, file) {
      const vm = this
      const objUrl = window.URL.createObjectURL(file)

      return new Promise((resolve) => {
        vm.$root.$once('onCropResizes', resolve)
        vm.$root.$emit('onCrop', { resizes, objUrl })
      })
    },

    async onFiles (evt) {
      const vm = this
      const files = Object.values(evt.target.files)
      const now = Date.now()

      for (const file of files) {
        const { name, size, type: mime } = file
        const id = `${now}-${vm.attachments.length}`
        const attachment = { name, size, mime, id, progress: 0, uploading: true, resizes: [] }

        if (mime.startsWith('image/')) {
          vm.isCropping = true
          attachment.resizes = await vm.cropImage(attachment, file)
          vm.isCropping = false
          if (!attachment.resizes.length) continue
        }

        vm.addFile(now, file, attachment)
      }

      vm.$nextTick().then(() => {
        setTimeout(() => {
          vm.vfiles = null
        }, 250)
      })
    },

    async addFile (now, file, attachment) {
      const vm = this

      vm.attachments.push(attachment)

      file.source = `${now}-${toSlug(name)}`
      const listUpload = []

      const onProgress = (progress) => {
        attachment.progress = Number((progress * 100).toFixed(1))
      }

      file.source = `${now}-${toSlug(name)}`

      listUpload.push(
        uploadS3(file, false, onProgress).then(({ id }) => {
          Object.assign(attachment, { id })
        })
      )

      for (const resize of attachment.resizes) {
        resize.blob.name = `${now}-${resize.data.width}x${resize.data.height}-${toSlug(name)}`
        resize.blob.source = resize.blob.name

        listUpload.push(
          uploadS3(resize.blob, true, onProgress).then(({ id, url }) => {
            Object.assign(resize, { id, url })
            delete resize.blob
          })
        )
      }

      Promise.all(listUpload).then(() => {
        Object.assign(attachment, { uploading: false })
      }).catch((err) => {
        console.log(err)
        const index = vm.attachments.indexOf(attachment)
        vm.attachments.splice(index, 1)
      })
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        const attachments = clone(value)
        if (isEqual(attachments, this.attachments)) return
        this.attachments = attachments
      }
    },

    attachments: {
      deep: true,
      handler (attachments) {
        if (isEqual(attachments, this.value)) return
        this.$emit('input', clone(attachments))
      }
    }
  }
}

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
const clone = (val) => JSON.parse(JSON.stringify(val))

</script>
