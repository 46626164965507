import { render, staticRenderFns } from "./FormPanel.vue?vue&type=template&id=0377ce13&scoped=true&"
import script from "./FormPanel.vue?vue&type=script&lang=js&"
export * from "./FormPanel.vue?vue&type=script&lang=js&"
import style0 from "./FormPanel.vue?vue&type=style&index=0&id=0377ce13&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0377ce13",
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QSplitter});
