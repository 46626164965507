<template>
  <q-page :style-fn="getAreaDimension">
    <div class="column form-render-class">
      <div
        class="col-auto"
        ref="areaBreadCrumb"
        style="z-index: 9"
      >
        <div class="column full-width">
          <div class="col-auto">
            <LayoutBreadcrumb :metadata="metadata">
              <div
                class="row no-wrap items-center justify-end"
                v-if="submitBottom"
              >
                <q-btn
                  flat
                  @click="onCancel"
                  label="Cancelar"
                  padding="4px 12px"
                  class="col-auto btnCancel q-mr-lg"
                  color="primary"
                />

                <q-btn
                  unelevated
                  color="primary"
                  class="col-auto btnSave"
                  padding="4px 12px"
                  :loading="isSaving || isSaved"
                  @click="isSaving ? null : handlerSave()"
                >
                  <b>Salvar</b>
                </q-btn>
              </div>
            </LayoutBreadcrumb>
          </div>
        </div>
      </div>
    </div>

    <div class="col max-container-width q-mx-auto">
      <q-scroll-area
        v-if="formHeight"
        :style="`height: ${formHeight}px`"
      >
        <div
          class="row justiyfy-around"
          ref="contentFormPanel"
        >
          <div class="col">
            <FormPanel
              :panel="form"
              v-if="isLoaded"
              v-model="input"
            />
          </div>
        </div>
      </q-scroll-area>
    </div>

    <q-inner-loading
      :showing="isLoading || isSaving"
      style="z-index: 9999"
    >
      <q-spinner-ios
        size="70px"
        color="primary"
      />
    </q-inner-loading>
  </q-page>
</template>

<script>
import { mapFields } from './reduceFields'
import FormPanel from './FormPanel'
import LayoutBreadcrumb from '@/components/layouts/LayoutBreadcrumb'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    FormPanel,
    LayoutBreadcrumb
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false
    },

    getUrl: {
      type: String
    },

    saveUrl: {
      type: String
    },

    successMessage: {
      type: String
    },

    form: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      default: () => ({})
    },

    afterGetData: {
      type: Function
    },

    beforeSendData: {
      type: Function
    },

    onSaved: {
      type: Function
    },

    submitBottom: {
      type: Boolean,
      default: true
    }
  },

  data () {
    const fields = mapFields(this.form)

    return {
      input: {},
      fields,
      isLoaded: false,
      formHeight: null
    }
  },

  mounted () {
    this.asyncValidate = 0
    this.getApi(this.getUrl)
  },

  beforeCreate () {
    this.$store.dispatch('formStore/clear')
  },

  beforeDestroy () {
    this.$store.dispatch('formStore/clear')
  },

  computed: {
    ...mapState({
      metadata: ({ formStore }) => formStore.data,
      dataSaved: ({ formStore }) => formStore.dataSaved,

      isSaved: ({ formStore }) => formStore.isSaved,
      isSaving: ({ formStore }) => formStore.isSaving,
      errorSave: ({ formStore }) => formStore.errorSave,

      isLoading: ({ formStore }) => formStore.isLoading
    })
  },

  methods: {
    ...mapActions({
      getApi: 'formStore/getApi',
      sendApi: 'formStore/sendApi'
    }),

    async handlerSave () {
      const vue = this
      const payload = clone(vue.input)
      payload.$state = await checkFields(vue.fields, payload)

      if (payload.$state) {
        const errors = Object.keys(payload.$state).reduce((acc, item) => item.error ? ++acc : acc, 0)
        vue.notify(`Por favor! Verifique as validações de cada campo antes de salvar!<br>${errors.length === 1 ? 'Ainda resta 1 campo inválido' : `Ainda restam ${errors.length} campos inválidos`}!`)
        vue.input = payload
        return
      }

      const body = vue.beforeSendData ? vue.beforeSendData(payload) : payload

      if (!vue.saveUrl) {
        return vue.onSaved(JSON.parse(JSON.stringify(body)))
      }

      vue.sendApi({
        body,
        isEdit: vue.isEdit,
        saveUrl: vue.saveUrl
      })
    },

    onCancel () {
      this.$router.go(-1)
    },

    getAreaDimension (offset, height) {
      const vue = this
      const areaHeight = height - offset
      if (areaHeight <= 0) return

      vue.$nextTick().then(() => {
        const { clientHeight: areaBreadCrumbHeight } = this.$refs.areaBreadCrumb
        vue.formHeight = areaHeight - areaBreadCrumbHeight - 8
      })
    },

    notify (message, color = 'negative') {
      this.$q.notify({
        color: color,
        icon: null,
        position: 'center',
        timeout: 7000,
        progress: true,
        html: true,
        message
      })
    }
  },

  watch: {
    input: {
      deep: true,
      handler (inputChanged, oldInput) {
        if (isEqual(inputChanged, oldInput)) return

        const { $state, ...input } = inputChanged
        const inputFields = Object.keys(input)
        const canCheckField = inputFields.length && Object.keys(oldInput).length
        const fieldChanged = canCheckField ? inputFields.find((field) => input[field] !== oldInput[field]) : null

        if (!fieldChanged) {
          const inputDate = Object.assign(input, { $state })
          !isEqual(inputDate, this.value) && this.$emit('input', inputDate)
          return
        }

        const asyncValidate = ++this.asyncValidate
        validate(this.fields, fieldChanged, input).then((error) => {
          if (asyncValidate !== this.asyncValidate) return
          $state[fieldChanged] = { dirty: true, error }
          const inputValidated = { ...this.input, $state }
          !isEqual(inputValidated, this.value) && this.$emit('input', inputValidated)
        })
      }
    },

    value: {
      deep: true,
      handler (value) {
        const input = clone(value)
        if (isEqual(this.input, input)) return
        Object.assign(this, { input })
      }
    },

    metadata (metadata) {
      if (this.isLoaded) return
      const data = clone({ ...this.value, ...metadata })
      const input = this.afterGetData ? this.afterGetData(data) : data
      input.$state = Object.keys(this.fields).reduce((acc, name) => ({ ...acc, [name]: { dirty: false, error: null } }), {})
      if (!isEqual(this.input, input)) Object.assign(this, { input, isLoaded: true })
    },

    isSaved (isSaved) {
      const vue = this
      if (!isSaved) return

      vue.$q.notify({
        icon: null,
        color: 'primary',
        position: 'center',
        timeout: 7000,
        progress: true,
        html: true,
        message: vue.successMessage || 'Registro salvo com sucesso!'
      })

      vue.onSaved
        ? vue.onSaved(vue.metadata)
        : vue.$router.go(-1)
    },

    errorSave (validation) {
      if (!validation) return
      const hasFieldErrors = validation.status === 400
      let alertMessage

      if (hasFieldErrors) {
        alertMessage = 'O servidor retornou algumas validações.<br>Por favor verique e tente novamente.'

        const isString = typeof validation.data === 'string'
        const isArray = Array.isArray(validation.data)

        if (isString) {
          alertMessage = `O servidor retornou algumas validações.<br><br><b>${validation.data}</b>`
        }

        if (isArray) {
          const input = clone(this.input)

          validation.data.forEach(({ message, property }) => {
            if (!input.$state[property]) input.$state[property] = {}
            input.$state[property].isDirty = true
            input.$state[property].error = message
          })

          this.input = input
        }
      } else {
        alertMessage = 'Erro desconhecido.<br>Por favor entre em contato com a equipe técnica.'
      }

      if (alertMessage) this.notify(alertMessage)
    }
  }
}

const isEqual = (target, source) => JSON.stringify(target) === JSON.stringify(source)
const clone = (target) => JSON.parse(JSON.stringify(target))

const validate = async (fields, name, input) => {
  const value = input[name]
  const field = fields[name]

  let showing = [true, undefined, null].includes(field.checkVisible)
  if (typeof field.checkVisible === 'function') showing = field.checkVisible(input)
  if (!showing) return null

  const rules = Array.isArray(field.rules) ? field.rules : []

  return rules
    .reduce((queue, check) => queue.then(() => check(value)), Promise.resolve())
    .then(() => null)
    .catch((err) => err)
}

const checkFields = async (fields, input) => {
  const state = {}
  let hasError = false

  await Promise.all(
    Object.keys(fields).map(async (field) => {
      const error = await validate(fields, field, input)
      if (error) hasError = true
      state[field] = { error, dirty: true }
    })
  )

  return hasError ? state : null
}

</script>

<style lang="sass">
  .form-render-class
    .footer-sticky
      z-index: 9
      position: -webkit-sticky
      position: sticky
      bottom: 0

    .btnSave
      font-style: normal
      font-weight: 600
      font-size: 0.8em

    .btnCancel
      font-style: normal
      font-weight: normal
      font-size: 0.8em
</style>
