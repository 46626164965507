import uploadS3 from './uploadS3'

const isPublic = true

class UploadS3Adapter {
  constructor (loader) {
    this.loader = loader
  }

  upload () {
    const lib = this

    return lib.loader.file.then((file) => (
      uploadS3(file, isPublic, (perc, loaded, total) => {
        lib.loader.uploaded = loaded
        lib.loader.uploadTotal = total
      })
    ))
  }

  abort () {}
}

export default UploadS3Adapter
