import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository'
import UploadS3Adapter from './UploadS3Adapter'

class UploadS3Plugin extends Plugin {
  static get requires () {
    return [FileRepository]
  }

  static get pluginName () {
    return 'uploadS3'
  }

  init () {
    this.editor.plugins.get('FileRepository').createUploadAdapter = (loader) => (
      new UploadS3Adapter(loader)
    )
  }
}

export default UploadS3Plugin
