<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Departamento'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    const vm = this
    const getters = vm.$store.getters
    const options = []

    if (getters.isGeneralRole) options.push({ id: 'G', label: 'Secretaria Geral' })
    if (getters.isRegionRole) options.push({ id: 'R', label: 'Secretaria Regional' })
    if (getters.isLocalRole) options.push({ id: 'L', label: 'Secretaria Nuclear' })

    return {
      selected: this.value,
      options
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

</script>
