<template>
  <div class="col-4 q-pa-xs">
    <q-card
      flat
      bordered
      class="border-radius-4 border-box"
    >
      <q-card-section
        horizontal
        class="items-center justify-around"
      >
        <q-circular-progress
          v-if="attach.uploading"
          show-value
          font-size="14px"
          indeterminate
          size="70px"
          :thickness="0.10"
          color="primary"
          track-color="grey-3"
        >
          {{ attach.progress }}%
        </q-circular-progress>

        <q-img
          basic
          v-else-if="isImage"
          :src="thumbURL"
          spinner-color="primary"
          class="col"
          style="height: 120px"
        >
          <q-tooltip>
            {{ attachName }}
            <br>
            {{ attachSize }}
          </q-tooltip>

          <template #loading>
            <q-spinner-ios color="primary" />
          </template>
        </q-img>

        <div
          v-else
          class="col"
        >
          <div class="row">
            <div class="col-auto q-mx-auto q-py-sm">
              <PdfIcon v-if="isPDF" />
              <WordIcon v-if="isWord" />
              <ExcelIcon v-if="isExcel" />
              <GenericIcon v-if="isGeneric" />
              <PowerPointIcon v-if="isPowerPoint" />
              <q-tooltip>
                {{ attachName }}
                <br>
                {{ attachSize }}
              </q-tooltip>
            </div>
          </div>
        </div>

        <q-separator
          vertical
          v-if="!readonly"
        />

        <q-card-actions
          vertical
          class="col-auto q-pa-none"
        >
          <q-btn
            flat
            unelevated
            size="sm"
            color="grey-8"
            icon="mdi-reorder-horizontal"
            class="handle"
            style="cursor: move"
            v-if="!readonly"
          >
            <q-tooltip>Mover</q-tooltip>
          </q-btn>

          <q-btn
            flat
            unelevated
            size="sm"
            color="primary"
            icon="mdi-eye-outline"
            :class="readonly ? 'link-download-readonly' : ''"
            @click="openAttachment"
          >
            <q-tooltip>Visualizar</q-tooltip>
          </q-btn>

          <q-btn
            flat
            unelevated
            size="sm"
            color="negative"
            icon="mdi-trash-can-outline"
            @click="$emit('on-remove')"
            v-if="!readonly"
          >
            <q-tooltip>Remover</q-tooltip>
          </q-btn>
        </q-card-actions>
      </q-card-section>

      <q-separator />

      <q-card-section
        class="q-py-xs q-px-sm ellipsis"
        style="font-size: 12px"
      >
        {{ attachName }}
      </q-card-section>

      <vue-easy-lightbox
        v-if="isImage"
        teleport="body"
        :visible="showAttachment"
        :imgs="[{ src: viewURL, title: attachName }]"
        @hide="closeLightbox"
      />
    </q-card>
  </div>
</template>

<script>
import PdfIcon from '@/assets/icons/PdfIcon.svg'
import WordIcon from '@/assets/icons/WordIcon.svg'
import ExcelIcon from '@/assets/icons/ExcelIcon.svg'
import GenericIcon from '@/assets/icons/GenericIcon.svg'
import PowerPointIcon from '@/assets/icons/PowerPointIcon.svg'
import superbytes from 'superbytes'
import { attachView } from '@/aws/api'
import ModalPDF from './ModalPDF'

export default {
  components: {
    PdfIcon,
    WordIcon,
    ExcelIcon,
    GenericIcon,
    PowerPointIcon
  },

  props: {
    size: {},
    attach: {},

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const viewURL = ''
    const thumbURL = ''
    const showAttachment = false
    return { viewURL, thumbURL, showAttachment }
  },

  mounted () {
    this.loadViewURL()
  },

  methods: {
    loadViewURL () {
      const vm = this
      if (vm.viewURL) return
      vm.thumbURL = getThumbnail(vm.attach, vm.size)

      const uploading = Boolean(vm.attach?.uploading)
      if (uploading) return

      attachView(vm.attach).then((viewURL) => {
        vm.viewURL = viewURL
        if (!vm.thumbURL) vm.thumbURL = viewURL
      })
    },

    async openAttachment () {
      const vue = this

      if (vue.isImage) {
        vue.showAttachment = true
        return
      }

      vue.$q.dialog({ component: ModalPDF, parent: vue, attach: vue.attach })
    },

    closeLightbox () {
      this.showAttachment = false
    }
  },

  computed: {
    attachName () {
      const attachName = String(this.attach?.name || '').trim().split('.')
      attachName.pop()
      return attachName.join('.')
    },

    attachSize () {
      const attachSize = Number(this.attach?.size || 0)
      return superbytes(attachSize)
    },

    isZip () {
      return isMime(this.attach, ['zip', 'rar'])
    },

    isPDF () {
      return isMime(this.attach, ['pdf'])
    },

    isWord () {
      return isMime(this.attach, ['word'])
    },

    isExcel () {
      return isMime(this.attach, ['spread', 'sheet', 'excel'])
    },

    isImage () {
      return isMime(this.attach, ['image'])
    },

    isPowerPoint () {
      return isMime(this.attach, ['presentation'])
    },

    isGeneric () {
      return !isMime(this.attach, ['zip', 'rar', 'pdf', 'word', 'spread', 'sheet', 'excel', 'image', 'presentation'])
    }
  },

  watch: {
    attach: {
      deep: true,
      handler () {
        this.loadViewURL()
      }
    }
  }
}

const lowerStr = (val) => String(val || '').trim().toLowerCase()

const isMime = (attach, mimes) => {
  const attachMime = lowerStr(attach?.mime)
  return Boolean(mimes.find((mime) => attachMime.includes(lowerStr(mime))))
}

export const getThumbnail = (image, size) => {
  const resizes = Array.isArray(image.resizes) ? image.resizes : []
  const resize = resizes.find(({ name }) => name === size)
  return resize?.url
}

</script>

<style lang="sass">
  .link-download-readonly
    position: absolute
    right: 0
    top: 0
</style>
