<template>
  <FormRender
    v-model="dataDocument"
    :form="form"
    :is-edit="isEdit"
    :get-url="getEndpoint"
    :save-url="saveEndpoint"
    :on-saved="handlerSaved"
    :success-message="successMessage"
  />
</template>

<script>
import moment from '@/components/moment'
import configForm from '../config/form'
import FormRender from '@/components/form/FormRender'

import {
  isAgencySGDG,
  coreSedeGeral,
  regionSedeGeral,
  isBoletimDesignacao
} from '@/components/documents'

import {
  ROUTE_DOCUMENT_EDIT,
  ROUTE_DOCUMENT_VIEW
} from '../constants'

export default {
  components: {
    FormRender
  },

  data () {
    const vm = this
    const getters = vm.$store.getters
    const session = getters.session

    const roles = {
      isGeneralRole: getters.isGeneralRole,
      isRegionRole: getters.isRegionRole,
      isLocalRole: getters.isLocalRole
    }

    let scope = 'G'
    if (getters.isLocalRole) scope = 'L'
    if (getters.isRegionRole) scope = 'R'
    if (getters.isGeneralRole) scope = 'G'

    const isEdit = vm.$route.name === ROUTE_DOCUMENT_EDIT
    const docId = isEdit ? vm.$route.params.id : null
    const getEndpoint = isEdit ? `/admin/documents/${docId}` : ''
    const saveEndpoint = isEdit ? `/admin/documents/${docId}` : '/admin/documents'
    const form = configForm(isEdit, roles)
    const successMessage = (isEdit ? 'Documento salvo com sucesso!' : 'Documento cadastrado com sucesso!')

    let coreAccess = []
    let regionAccess = []

    if (['L', 'R'].includes(scope)) regionAccess = [{ id: Number(session['custom:regiao_codigo']), name: session['custom:regiao_nome'] }]
    if (scope === 'L') coreAccess = [{ id: Number(session['custom:nucleo_codigo']), name: `${session['custom:nucleo_nome']}` }]

    const dataDocument = {
      scope,
      timezone: moment().format('Z'),
      coreAccess,
      regionAccess,
      boardCouncil: [],
      scheduleAt: moment().unix(),
      materialDate: moment().unix(),
      degreeId: 1,
      scheduleHighlight: false,
      isBoQm: false
    }

    return {
      form,
      isEdit,
      getEndpoint,
      dataDocument,
      saveEndpoint,
      successMessage
    }
  },

  mounted () {
    const vm = this
    const isAdmin = vm.$store.getters.isAdmin
    if (!isAdmin) return vm.$router.go(-1)
  },

  methods: {
    handlerSaved ({ id }) {
      this.$router.push({ name: ROUTE_DOCUMENT_VIEW, params: { id } })
    }
  },

  watch: {
    dataDocument: {
      deep: true,
      handler (newDoc, oldDoc) {
        if (isEqual(newDoc, oldDoc)) return
        const doc = newDoc ? { ...newDoc } : {}

        if ([null, undefined, ''].includes(doc.isBoQm)) doc.isBoQm = false

        if (isBoletimDesignacao(doc) || isAgencySGDG(doc)) {
          doc.coreId = coreSedeGeral
          doc.regionId = regionSedeGeral
        }

        const clearCoreId = doc.regionId !== regionSedeGeral && doc.coreId === coreSedeGeral
        if (clearCoreId) delete doc.coreId

        if (['L', 'R'].includes(doc.scope)) {
          const session = this.$store.getters.session

          doc.regionAccess = [{
            id: Number(session['custom:regiao_codigo']),
            name: session['custom:regiao_nome']
          }]

          if (doc.scope === 'L') {
            doc.boardCouncil = []

            // doc.coreAccess = [{
            //   id: Number(session['custom:nucleo_codigo']),
            //   name: `${session['custom:nucleo_nome']}`
            // }]
          }
        }

        const disableCores = !(['R', 'G'].includes(newDoc.scope) || [4, 3].includes(newDoc.degreeId))
        if (disableCores) {
          const session = this.$store.getters.session

          doc.coreAccess = [{
            id: Number(session['custom:nucleo_codigo']),
            name: `${session['custom:nucleo_nome']}`
          }]
        }

        if (!isEqual(newDoc, doc)) this.dataDocument = doc
      }
    }
  }
}

const isEqual = (target, source) => (
  JSON.stringify(clearModel(target)) === JSON.stringify(clearModel(source))
)

const clearModel = (model) => (
  Object.keys(model).sort().reduce((acc, key) => {
    if (!key || key[0] === '$') return acc
    if ([null, undefined].includes(model[key])) return acc
    return { ...acc, [key]: model[key] }
  }, {})
)

</script>
