<template>
  <FieldMultiSelect
    :label="label"
    :state="state"
    :disabled="disabled"
    :is-required="isRequired"
    :class-label="classLabel"

    :placeholder="placeholder"

    option-value="id"
    option-label="name"
    v-model="selected"

    :use-input="true"
    :allow-add-options="false"
    :load-options="loadOptions"
  />
</template>

<script>
import api from '@/aws/api'
import FieldMultiSelect from '@/components/forms/FieldMultiSelect'

export default {
  components: {
    FieldMultiSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    extraField: {}
  },

  data () {
    return {
      selected: toSelected(this.value)
    }
  },

  computed: {
    placeholder () {
      const hasValue = Array.isArray(this.selected) && this.selected.length
      return hasValue ? '' : 'Selecionar'
    },

    scope () {
      return String(this.extraField || '').toUpperCase().trim()
    }
  },

  methods: {
    loadOptions (val) {
      const vm = this
      const limit = 40
      const removeRoles = []
      const isLimited = ['R', 'L'].includes(vm.scope)
      if (isLimited) removeRoles.push(4, 6, 7, 8, 9)

      return api.getRoles({ $: val, limit }).then(({ rows }) => (
        rows.filter((row) => !removeRoles.includes(row.id))
      ))
    }
  },

  watch: {
    selected (selected) {
      if (isEqual(selected, this.value)) return
      this.$emit('input', toSelected(selected))
    },

    value (value) {
      if (isEqual(this.selected, value)) return
      this.selected = toSelected(value)
    }
  }
}

const toSelected = (val) => {
  if (Array.isArray(val)) return [...val]
  return val ? [val] : null
}

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>
