import isRequired from '@/components/form/validations/isRequired'

import FieldText from '@/components/forms/FieldText'
import FieldCalendar from '@/components/forms/FieldCalendar'
import FieldBoolean from '@/components/forms/FieldBoolean'
import FieldDateTime from '@/components/forms/FieldDateTime'
import FieldAttachments from '@/components/forms/FieldAttachments'

import FieldUrl from '@/components/forms/FieldUrl'
import FieldDate from '@/components/forms/FieldDate'
import FieldNumber from '@/components/forms/FieldNumber'
import FieldBoardCouncil from '@/modules/documents/components/FieldBoardCouncil'

import FieldCores from '@/modules/documents/components/FieldCores'
import FieldRegions from '@/modules/documents/components/FieldRegions'
import FieldRoles from '@/modules/documents/components/FieldRoles'
import FieldCore from '@/modules/documents/components/FieldCore'
import FieldRegion from '@/modules/documents/components/FieldRegion'
import FieldFuseHours from '@/modules/documents/components/FieldFuseHours'
import FieldScope from '@/modules/documents/components/FieldScope'
import FieldDegree from '@/modules/documents/components/FieldDegree'
import FieldAgency from '@/modules/documents/components/FieldAgency'
import FieldKeywords from '@/modules/documents/components/FieldKeywords'
import FieldDocumentType from '@/modules/documents/components/FieldDocumentType'
import FieldDocumentSubType from '@/modules/documents/components/FieldDocumentSubType'
import FieldEditor from '@/components/forms/FieldEditor'

import {
  hasLink,
  hasSubType,
  isAgencyRegion,
  isAgencyCoreDav,
  definedClassWithNumeration,
  hasNumeration,
  isBoletim,
  isBoletimDesignacao,
  hasDescription,
  definedLabelSubType,
  definedLabelNumeration,
  definedLabelDescription,
  definedLabelDate
} from '@/components/documents'

export default (_, roles) => ({
  class: 'col',
  panels: [{
    class: 'col q-py-sm q-px-xs',
    panels: [{
      class: 'col-12 q-mt-sm bg-white border-radius-4 border-box q-pa-sm',
      steps: [{
        label: 'Informações',
        icon: 'mdi-view-grid-outline',
        form: {
          class: 'col-12',
          fields: [{
            name: 'materialDate',
            class: 'col-sm-4',
            label: definedLabelDate,
            component: FieldDate,
            isRequired: true,
            rules: [isRequired()]
          }, {
            name: 'documentTypeId',
            class: 'col-sm-8',
            label: 'Tipo do Documento',
            isRequired: true,
            component: FieldDocumentType,
            rules: [isRequired()]
          }, {
            name: 'numeration',
            class: 'col-sm-4',
            label: definedLabelNumeration,
            component: FieldNumber,
            placeholder: 'Númeração do Documento',
            isRequired: true,
            rules: [isRequired()],
            checkVisible: hasNumeration
          }, {
            name: 'documentSubTypeId',
            class: definedClassWithNumeration,
            label: definedLabelSubType,
            component: FieldDocumentSubType,
            extraField: 'documentTypeId',
            placeholder: 'Informe o tipo',
            isRequired: true,
            rules: [isRequired()],
            checkVisible: hasSubType
          }, {
            name: 'isBoQm',
            class: 'col-sm-4',
            label: 'Boletim do QM?',
            component: FieldBoolean,
            isRequired: true,
            rules: [isRequired()],
            checkVisible: isBoletim
          }, {
            name: 'person',
            class: 'col-12',
            label: 'Nome do Mestre/Conselheiro',
            component: FieldText,
            placeholder: 'Informe o nome do Mestre ou Conselheiro',
            isRequired: isBoletimDesignacao,
            rules: isBoletimDesignacao ? [isRequired()] : [],
            checkVisible: isBoletimDesignacao
          }, {
            name: 'description',
            label: definedLabelDescription,
            class: 'col-sm-12',
            component: FieldText,
            placeholder: 'Descrição e Finalidade',
            isRequired: true,
            rules: [isRequired()],
            checkVisible: hasDescription
          }, {
            name: 'url',
            label: 'Link/URL',
            class: 'col-sm-12',
            component: FieldUrl,
            placeholder: 'https://',
            isRequired: false,
            checkVisible: hasLink
          }, {
            name: 'agencyId',
            class: 'col-sm-5',
            label: 'Órgão Emissor',
            component: FieldAgency,
            isRequired: true,
            rules: [isRequired()]
          }, {
            name: 'regionId',
            class: 'col-sm-12',
            label: 'Região',
            component: FieldRegion,
            isRequired: true,
            rules: [isRequired()],
            checkVisible: isAgencyRegion
          }, {
            name: 'coreId',
            class: 'col-sm-12',
            label: 'Núcleo',
            component: FieldCore,
            extraField: 'regionId',
            isRequired: true,
            rules: [isRequired()],
            checkVisible: isAgencyCoreDav
          }, {
            name: 'keywords',
            label: 'Palavras Chaves',
            class: 'col-sm-12',
            component: FieldKeywords
          }]
        }
      }, {
        label: 'Regras',
        icon: 'mdi-account-box',
        form: {
          class: 'col-12',
          fields: [{
            name: 'scope',
            class: 'col-sm-12',
            label: 'Âmbito',
            component: FieldScope,
            isRequired: true,
            rules: [isRequired()],
            checkVisible: () => [roles.isRegionRole, roles.isGeneralRole, roles.isLocalRole].filter(v => v).length > 1
          }, {
            name: 'degreeId',
            class: 'col-sm-5',
            label: 'Grau do Conteúdo',
            isRequired: true,
            component: FieldDegree,
            rules: [isRequired()]
          }, {
            name: 'boardCouncil',
            class: 'col-sm-7',
            label: 'Reservar aos Colegiados',
            extraField: 'scope',
            component: FieldBoardCouncil,
            checkVisible: (input) => ['R', 'G'].includes(input.scope)
          }, {
            name: 'roles',
            class: 'col-sm-12',
            label: 'Habilitar Acesso aos Cargos',
            extraField: 'scope',
            component: FieldRoles
          }, {
            name: 'regionAccess',
            class: 'col-sm-12',
            label: 'Reservar acesso às Regiões',
            component: FieldRegions,
            checkDisabled: (input) => ['R', 'L'].includes(input.scope)
          }, {
            name: 'coreAccess',
            class: 'col-sm-12',
            label: 'Reservar acesso aos Núcleos',
            placeholder: 'Esse campo Vazio indica que os núcleos da região terão acesso',
            component: FieldCores,
            extraField: 'regionAccess',
            checkDisabled: (input) => !(['R', 'G'].includes(input.scope) || [4, 3].includes(input.degreeId))
          }]
        }
      }, {
        label: 'Agendamento',
        icon: 'mdi-calendar-clock',
        form: {
          class: 'col-12',
          fields: [{
            name: 'scheduleAt',
            class: 'col-sm-4',
            label: 'Data/Hora',
            component: FieldCalendar
          }, {
            name: 'timezone',
            class: 'col-sm-8',
            label: 'Fuso Horário',
            component: FieldFuseHours
          }, {
            name: 'scheduleHighlight',
            class: 'col-sm-4',
            label: 'Destaque no Mobile',
            isRequired: true,
            rules: [isRequired()],
            component: FieldBoolean
          }, {
            name: 'scheduleHighlightFinishAt',
            class: 'col-sm-8',
            label: 'Período em Destaque',
            component: FieldDateTime,
            checkVisible: hasHighlight
          }, {
            name: 'scheduleTitle',
            label: 'Título',
            class: 'col-sm-12',
            component: FieldText,
            placeholder: 'Título da Publicação'
          }, {
            name: 'scheduleResume',
            label: 'Post da Divulgação',
            class: 'col-sm-12',
            component: FieldEditor
          }]
        }
      }, {
        label: 'Imagens',
        icon: 'mdi-camera',
        form: {
          class: 'col-12',
          fields: [{
            name: 'images',
            class: 'col-sm-12',
            accept: 'image/png,image/jpg,image/jpeg',
            padding: 'q-py-none q-px-sm',
            component: FieldAttachments
          }]
        }
      }, {
        label: 'Anexos',
        icon: 'mdi-paperclip',
        form: {
          class: 'col-12',
          fields: [{
            name: 'attachments',
            class: 'col-sm-12',
            accept: 'image/png,image/jpg,image/jpeg,application/pdf',
            padding: 'q-py-none q-px-sm',
            component: FieldAttachments
          }]
        }
      }]
    }]
  }]
})

const hasHighlight = ({ scheduleHighlight }) => ['true', 't', '1', 'yes', 'y'].includes(String(scheduleHighlight).toLowerCase())
