<template>
  <div>
    <FullCalendar
      v-if="options"
      ref="fullCalendar"
      :options="options"
    />

    <q-inner-loading
      :showing="isLoading"
      style="z-index: 9999"
    >
      <q-spinner-ios
        size="70px"
        color="primary"
      />
    </q-inner-loading>
  </div>
</template>

<script>
import api from '@/aws/api'
import router from '@/router'
import { ROUTE_DOCUMENT_VIEW } from '@/modules/documents/constants'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import moment from '@/components/moment'
import allLocales from '@fullcalendar/core/locales-all'

export default {
  components: {
    FullCalendar
  },

  data () {
    return {
      options: null,
      isLoading: true,
      iniTimestamp: null,
      endTimestamp: null,
      fullCalendar: null
    }
  },

  mounted () {
    this.options = startCalendar(this)
  },

  methods: {
    loadEvents (ini, end) {
      const vue = this
      const iniTimestamp = toTimestamp(ini)
      const endTimestamp = toTimestamp(end)
      const isNotChanged = vue.iniTimestamp === iniTimestamp && vue.endTimestamp === endTimestamp
      if (isNotChanged) return

      const updateState = { isLoading: true, iniTimestamp, endTimestamp }
      if (!vue.fullCalendar) updateState.fullCalendar = this.$refs.fullCalendar.getApi()
      Object.assign(vue, updateState)

      vue.$nextTick().then(async () => {
        const period = `${iniTimestamp},${endTimestamp}`
        const res = await api.getSchedules({ period }).catch(() => [])
        const events = res.rows.map(scheduleToEvent)
        vue.fullCalendar.setOption('events', events)
        vue.isLoading = false
      })
    },

    handleDateClick (evt) {
      const isMonthView = evt.view.type === 'dayGridMonth'
      const timestamp = moment(evt.date).unix()
      if (!isMonthView) return this.$emit('on-change', timestamp)
      evt.view.calendar.changeView('timeGridDay')
      evt.view.calendar.gotoDate(evt.date)
    }
  }
}

const locales = allLocales.filter((item) => ['pt', 'pt-br', 'en', 'es', 'fr'].includes(item.code))
const initialDate = moment().format('YYYY-MM-DD')
const toTimestamp = (date) => Math.floor(date.getTime() / 1000)

const scheduleToEvent = (item) => {
  return {
    title: `${item.degreePrefix} - ${item.description}`,
    date: moment.unix(item.scheduleAt).toDate(),
    url: router.resolve({ name: ROUTE_DOCUMENT_VIEW, params: { id: item.id } }).href
  }
}

const plugins = [interactionPlugin, dayGridPlugin, listPlugin, timeGridPlugin, momentTimezonePlugin]

const startCalendar = (vue) => ({
  events: [],
  locales,
  plugins,
  initialDate,

  dateClick: (evt) => vue.handleDateClick(evt),
  datesSet: ({ start, end }) => vue.loadEvents(start, end),

  eventClick: (info) => {
    info.jsEvent.preventDefault()
    window.open(info.event.url)
  },

  viewHint: function (buttonText, buttonName) {
    if (buttonName.match(/^dayGrid/)) { // matches "dayGridWeek"
      return buttonText + ' list view' // results in "week list view"
    } else {
      return buttonText + ' view' // results in "week view"
    }
  },

  locale: 'pt-br',
  timeZone: 'America/Sao_Paulo',
  initialView: 'dayGridMonth',
  // titleFormat: 'MMMM [!!!] YYYY',

  navLinks: true,
  editable: true,
  dayMaxEvents: true,

  selectable: true,
  selectMirror: true,
  weekends: true,

  rerenderDelay: 350,

  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
  }
})

</script>
