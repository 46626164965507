<template>
  <FieldWrapper
    :label="label"
    :state="state"
    :is-required="isRequired"
    :class-label="classLabel"
    :class-wrapper="classWrapper"
    v-if="selected.length || !disabled"
  >
    <div
      v-if="isLoading"
      class="col q-pt-sm"
    >
      <q-spinner-ios
        size="24px"
        color="primary"
      />
    </div>

    <template v-else>
      <template v-if="disabled">
        <div class="row">
          <q-chip
            v-for="item in selected"
            :key="item[optionValue]"
            dense
            outline
            square
            size="0.95em"
            :color="color"
            :icon="icon"
            style="margin: 0 4px 3px 0"
            class="col-auto"
            :label="item[optionLabel]"
          />
        </div>
      </template>

      <q-select
        v-else
        dense
        outlined
        multiple
        options-dense
        hide-dropdown-icon

        v-model="selected"
        class="myInput"
        bg-color="white"
        transition-show="fade"

        :use-input="useInput || allowAddOptions"
        :options="list"
        :placeholder="placeholder"

        :option-value="optionValue"
        :option-label="optionLabel"

        @filter="filterOptions"
        @new-value="createValue"
      >
        <template #loading>
          <q-inner-loading :showing="true">
            <q-spinner-gears
              size="50px"
              color="primary"
            />
          </q-inner-loading>
        </template>

        <template #selected>
          <span v-if="!useInput && !allowAddOptions && !selected.length">
            {{ placeholder }}
          </span>

          <q-chip
            v-else
            v-for="item in selected"
            :key="item[optionValue]"
            outline
            square
            size="0.95em"
            :color="color"
            style="margin: 3px 4px 0 4px"
            :icon="icon"
            removable
            @remove="handleRemove(item)"
          >
            {{ item[optionLabel] }}
          </q-chip>
        </template>

        <template #option="{ opt }">
          <q-item
            dense
            v-if="unselected(opt)"
            class="q-px-sm"
          >
            <q-item-section>
              <q-checkbox
                size="xs"
                color="primary"
                v-model="selected"
                :val="opt"
                class="q-pr-sm"
                :label="getOptionDescription(opt)"
              />
              <q-separator />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </template>
  </FieldWrapper>
</template>

<script>
import FieldWrapper from '@/components/forms/FieldWrapper'

export default {
  components: {
    FieldWrapper
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    icon: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Escreva aqui'
    },

    optionValue: {
      type: String,
      default: 'id'
    },

    optionLabel: {
      type: String,
      default: 'prefix'
    },

    optionDescription: {
      type: String,
      default: 'name'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    useInput: {
      type: Boolean,
      default: false
    },

    allowAddOptions: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    classWrapper: {
      type: String
    },

    color: {
      type: String,
      default: 'green-10'
    },

    options: {
      type: Array
    },

    loadOptions: {
      type: Function
    }
  },

  data () {
    return {
      list: toSelected(this.options),
      selected: toSelected(this.value)
    }
  },

  computed: {
    fixedOptions () {
      return Array.isArray(this.options)
    }
  },

  methods: {
    unselected (opt) {
      const { optionValue } = this
      return !this.selected.find((item) => item[optionValue] === opt[optionValue])
    },

    filterOptions (val, update, abort) {
      const vue = this

      if (vue.fixedOptions) {
        return update(() => {
          const { optionLabel } = this
          const minVal = toMinStr(val)
          this.list = this.options.filter((option) => (
            toMinStr(option[optionLabel]).includes(minVal)
          ))
        })
      }

      vue.loadOptions(val).catch(abort).then((list) => {
        update(() => Object.assign(vue, { list }))
      })
    },

    getOptionDescription (opt) {
      const { optionLabel, optionDescription } = this
      return opt[optionDescription] ?? opt[optionLabel]
    },

    handleRemove (item) {
      const { optionValue } = this
      const selected = toSelected(this.selected)
      const index = selected.findIndex((sel) => sel[optionValue] === item[optionValue])
      if (index > -1) selected.splice(index, 1)
      Object.assign(this, { selected })
    },

    createValue (val, done) {
      if (!this.allowAddOptions) return done()

      const { optionLabel, optionValue } = this
      const selected = toSelected(this.selected)
      const minVal = toMinStr(val)

      const hasVal = selected.find((item) => toMinStr(item[optionLabel]) === minVal)
      if (hasVal) return done()

      done({ [optionValue]: Date.now(), [optionLabel]: val })
    }
  },

  watch: {
    selected (selected) {
      const oldSelected = toSelected(this.value)
      const newSelected = toSelected(selected)
      if (isEqual(oldSelected, newSelected)) return
      this.$emit('input', newSelected)
    },

    value (value) {
      const oldSelected = toSelected(this.selected)
      const newSelected = toSelected(value)
      if (isEqual(oldSelected, newSelected)) return
      this.selected = newSelected
    },

    isLoading (isLoading) {
      if (!isLoading) this.list = toSelected(this.options)
    }
  }
}

const toSelected = (val) => {
  if (Array.isArray(val)) return [...val]
  return val ? [val] : []
}

const clearStr = (str) => String(str || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()
const toMinStr = (str) => clearStr(str).toLowerCase()
const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>
