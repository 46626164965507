const forceArray = (v) => Array.isArray(v) ? v : []

const reduceFields = (panel) => {
  if (!panel) return []
  const stepFields = forceArray(panel?.steps).map(({ form }) => reduceFields(form)).flat()
  const panelFields = forceArray(panel?.panels).map(reduceFields).flat()
  return [forceArray(panel?.fields), panelFields, stepFields].flat().filter(({ name }) => name)
}

export const mapFields = (form) => (
  reduceFields(form).reduce((acc, field) => ({ ...acc, [field.name]: field }), {})
)

export default reduceFields
