<template>
  <FieldMultiSelect
    :label="label"
    :state="state"
    :disabled="disabled"
    :is-required="isRequired"
    :class-label="classLabel"
    :class-wrapper="classWrapper"
    :placeholder="placeholder"
    :emit-value="true"

    option-value="id"
    option-label="name"
    v-model="selected"

    :use-input="true"
    :allow-add-options="false"
    :load-options="loadOptions"
  />
</template>

<script>
import api from '@/aws/api'
import FieldMultiSelect from '@/components/forms/FieldMultiSelect'

const oitavaRegiao = 9
const regiaoSedeGeral = 1

export default {
  components: {
    FieldMultiSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    classWrapper: {
      type: String
    },

    extraField: {}
  },

  data () {
    const vm = this
    const isString = typeof vm.value === 'string'
    const isNumber = typeof vm.value === 'number'

    let selected = []
    let loadAfter = isString || isNumber ? String(vm.value).trim().split(',').filter(v => v.trim()) : []

    if (loadAfter.length) {
      loadAfter = loadAfter.join(',')
    } else {
      selected = toSelected(this.value)
    }

    return {
      selected,
      loadAfter
    }
  },

  mounted () {
    const vm = this
    vm.startLoadAfter()
  },

  computed: {
    placeholder () {
      const hasValue = Array.isArray(this.selected) && this.selected.length
      return hasValue ? '' : 'Esse campo Vazio indica que os núcleos da região terão acesso'
    },

    regions () {
      return Array.isArray(this.extraField) ? this.extraField.map(({ id }) => id) : []
    }
  },

  methods: {
    startLoadAfter () {
      const vm = this

      if (!vm.loadAfter.length) return
      const hasMultiples = !vm.regions.length || vm.regions.length > 1

      api.getCores({ reuniId: vm.loadAfter, limit: 999999 }).then(({ rows }) => {
        const selected = rows.map((row) => ({
          id: row.reuniId,
          name: hasMultiples ? `${row.regionName} - ${row.name}` : row.name
        }))

        if (isEqual(vm.selected, selected)) return
        vm.selected = selected
      })
    },

    loadOptions (val) {
      const vm = this
      const params = { $: val, limit: 30 }

      const regions = Array.isArray(vm.regions) ? vm.regions : []
      if (regions.includes(oitavaRegiao)) regions.push(regiaoSedeGeral)
      const hasRegions = Boolean(vm.regions.length)

      if (hasRegions) params.regionId = regions.join(',')

      const hasMultiples = !regions.length || regions.length > 1

      return api.getCores(params).then(({ rows }) => (
        rows.map((row) => {
          const id = row.reuniId
          let name = hasMultiples ? `${row.regionName} - ${row.name}` : row.name
          if (row.reuniId === 1) name = 'Sede Geral'
          return { id, name }
        })
      ))
    }
  },

  watch: {
    selected (selected) {
      if (isEqual(selected, this.value)) return
      this.$emit('input', toSelected(selected))
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const isString = typeof value === 'string'
        const isNumber = typeof value === 'number'
        const loadAfter = isString || isNumber ? String(vm.value).trim().split(',').filter(v => v.trim()) : []

        if (loadAfter.length) {
          vm.loadAfter = loadAfter.join(',')
          vm.startLoadAfter()
        } else {
          if (isEqual(this.selected, value)) return
          this.selected = toSelected(value)
        }
      }
    }
  }
}

const toSelected = (val) => {
  if (Array.isArray(val)) return [...val]
  return val ? [val] : null
}

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>
