<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :options="options"
    :multiple="multiple"
    :disabled="disabled"
    :is-required="isRequired"
    :class-label="classLabel"
    :placeholder="placeholder"
  />
</template>

<script>
import api from '@/aws/api'
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Núcleos'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    multiple: {},

    extraField: {},

    asyncKey: {
      type: String,
      default: 'id'
    }
  },

  data () {
    return {
      selected: this.value,
      options: []
    }
  },

  mounted () {
    this.loadOptions()
  },

  methods: {
    loadOptions () {
      const vue = this
      const regionId = vue.extraField
      const params = regionId ? { regionId } : {}
      params.limit = 999999

      api.getCores(params).then(({ rows }) => {
        vue.options = vue.extraField ? rows.map((core) => toOption(core, vue.asyncKey)) : reduceRegions(rows, vue.asyncKey)
      })
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    },

    extraField () {
      const vue = this
      vue.options = []
      vue.$nextTick().then(() => vue.loadOptions())
    }
  }
}

const reduceRegions = (cores, asyncKey) => (
  Object.values(cores.reduce((acc, core) => {
    if (!acc[core.regionId]) acc[core.regionId] = { id: `r${core.regionId}`, label: core.regionName, children: [] }
    acc[core.regionId].children.push(toOption(core, asyncKey))
    return acc
  }, {}))
)

const toOption = (core, asyncKey) => ({
  id: core[asyncKey],
  label: core.name
})

</script>
