<template>
  <q-dialog
    ref="dialog"
    full-width
    full-height
    @hide="onDialogHide"
    @show="onDialogShow"
  >
    <q-card class="q-dialog-plugin">
      <q-inner-loading
        v-if="loading"
        style="z-index: 9999"
      >
        <q-spinner-ios
          size="70px"
          color="primary"
        />
      </q-inner-loading>

      <PDFViewer
        v-else
        :source="url"
        style="height: 100vh; width: 100vw"
        :controls="['print', 'rotate', 'zoom', 'catalog', 'switchPage']"
      />
    </q-card>
  </q-dialog>
</template>

<script>
import api from '@/aws/api'
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'

export default {
  components: {
    PDFViewer
  },

  props: {
    attach: {}
  },

  data () {
    return { loading: true, url: null }
  },

  methods: {
    show () {
      this.$refs.dialog.show()
    },

    hide () {
      this.loading = true
      this.$refs.dialog.hide()
    },

    onDialogHide () {
      this.$emit('hide')
    },

    async onDialogShow () {
      const vm = this
      vm.loading = true
      vm.url = await api.getAttachS3Link(vm.attach.id).then(({ original }) => original)
      vm.loading = false
    }
  }
}
</script>
