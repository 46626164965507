<template>
  <q-dialog
    ref="dialog"
    full-width
    full-height
    @hide="onDialogHide"
  >
    <q-card class="q-dialog-plugin">
      <div class="column">
        <div
          class="col q-px-md q-py-xs"
          v-if="showCalendar"
        >
          <ScheduleCalendar
            @on-change="handlerSelect"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import ScheduleCalendar from './ScheduleCalendar'

export default {
  components: {
    ScheduleCalendar
  },

  data () {
    return { showCalendar: false }
  },

  methods: {
    show () {
      this.$refs.dialog.show()
      setTimeout(() => {
        this.showCalendar = true
      }, 800)
    },

    hide () {
      this.$refs.dialog.hide()
    },

    onDialogHide () {
      this.$emit('hide')
    },

    onOKClick () {
      this.$emit('ok')
      this.hide()
    },

    onCancelClick () {
      this.hide()
    },

    handlerSelect (timestamp) {
      this.$emit('ok', timestamp)
      this.hide()
    }
  }
}
</script>
