<template>
  <DatePicker
    v-model="timestamps"
    :placeholder="placeholder"
    :disabled="disabled"
    :range="range"
    :type="type"
    :lang="langPtBR"
    :format="format"
    ref="inputDatePicker"
    value-type="timestamp"
  />
</template>

<script>
import DatePicker from 'vue2-datepicker'
import langPtBR from 'vue2-datepicker/locale/pt-br'

export default {
  components: {
    DatePicker
  },

  props: {
    type: {},
    value: {},
    format: {},
    placeholder: {},

    range: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const timestamps = toTime(this.value, this.range)

    return {
      langPtBR,
      timestamps
    }
  },

  mounted () {
    this.$refs.inputDatePicker.$refs.input.setAttribute('readonly', 'readonly')
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        const timestamps = toTime(value, this.range)
        if (!isEqual(this.timestamps, timestamps)) this.timestamps = timestamps
      }
    },

    timestamps: {
      deep: true,
      handler (timestamps) {
        const value = toTimestamp(timestamps, this.range)
        if (!isEqual(this.value, value)) this.$emit('input', value)
      }
    }
  }
}

const toTime = (timestamps, isRange) => {
  if (!Array.isArray(timestamps)) timestamps = timestamps ? [timestamps] : []

  for (let i = 0, c = timestamps.length; i < c; i++) {
    if (typeof timestamps[i] === 'number') {
      timestamps[i] = timestamps[i] * 1000
      continue
    }

    if (timestamps[i]?.getTime()) {
      timestamps[i] = timestamps[i].getTime()
      continue
    }

    if (isNaN(timestamps[i])) {
      timestamps[i] = null
      continue
    }

    timestamps[i] = Number(timestamps[i]) * 1000
  }

  return isRange ? timestamps : timestamps.pop()
}

const toTimestamp = (timestamps, isRange) => {
  if (!Array.isArray(timestamps)) timestamps = [timestamps]

  for (let i = 0, c = timestamps.length; i < c; i++) {
    if (typeof timestamps[i] === 'number') {
      timestamps[i] = Math.floor(timestamps[i] / 1000)
      continue
    }

    if (timestamps[i]?.getTime()) {
      timestamps[i] = Math.floor(timestamps[i].getTime() / 1000)
      continue
    }

    if (isNaN(timestamps[i])) {
      timestamps[i] = null
      continue
    }

    timestamps[i] = Math.floor(Number(timestamps[i]) / 1000)
  }

  return isRange ? timestamps : timestamps.pop()
}

const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

</script>

<style lang="scss">
  @import 'vue2-datepicker/scss/var.scss';
  @import 'vue2-datepicker/scss/icon.scss';
  @import 'vue2-datepicker/scss/btn.scss';
  @import 'vue2-datepicker/scss/scrollbar.scss';
  @import 'vue2-datepicker/scss/animation.scss';

  .#{$namespace}-datepicker {
    position: relative;
    display: inline-block;
    width: 100%;
    svg {
      width: 1em;
      height: 1em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }

  .#{$namespace}-datepicker-range {
    width: 100%;
  }

  .#{$namespace}-datepicker-inline {
    width: auto;
  }

  .#{$namespace}-input-wrapper {
    position: relative;
    .#{$namespace}-icon-clear {
      display: none;
    }
    &:hover {
      .#{$namespace}-icon-clear {
        display: block;
      }
      .#{$namespace}-icon-clear + .#{$namespace}-icon-calendar {
        display: none;
      }
    }
  }

  .#{$namespace}-input {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: $input-color;
    background-color: #fff;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:hover,
    &:focus {
      border-color: $input-hover-border-color;
    }
    &:disabled,
    &.disabled {
      color: $disabled-color;
      background-color: $disabled-background-color;
      border-color: $input-border-color;
      cursor: not-allowed;
    }
    &:focus {
      outline: none;
    }
    &::-ms-clear {
      display: none;
    }
  }

  .#{$namespace}-icon-calendar,
  .#{$namespace}-icon-clear {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 1;
    color: rgba(0, 0, 0, 0.5);
    vertical-align: middle;
  }

  .#{$namespace}-icon-clear {
    cursor: pointer;
    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .#{$namespace}-datepicker-main {
    font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei', sans-serif;
    color: $default-color;
    background-color: #fff;
    border: 1px solid $border-color;
  }

  .#{$namespace}-datepicker-popup {
    position: absolute;
    margin-top: 1px;
    margin-bottom: 1px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    z-index: $popup-z-index;
  }

  .#{$namespace}-datepicker-sidebar {
    float: left;
    box-sizing: border-box;
    width: $sidebar-margin-left;
    padding: 6px;
    overflow: auto;
  }

  .#{$namespace}-datepicker-sidebar + .#{$namespace}-datepicker-content {
    margin-left: $sidebar-margin-left;
    border-left: 1px solid $border-color;
  }

  .#{$namespace}-datepicker-body {
    position: relative;
    user-select: none;
  }

  .#{$namespace}-btn-shortcut {
    display: block;
    padding: 0 6px;
    line-height: 24px;
  }

  .#{$namespace}-range-wrapper {
    display: flex;
    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .#{$namespace}-datepicker-header {
    padding: 6px 8px;
    border-bottom: 1px solid $border-color;
  }

  .#{$namespace}-datepicker-footer {
    padding: 6px 8px;
    text-align: right;
    border-top: 1px solid $border-color;
  }

  .#{$namespace}-calendar {
    box-sizing: border-box;
    width: 248px;
    padding: 6px 12px;
    & + & {
      border-left: 1px solid $border-color;
    }
  }

  .#{$namespace}-calendar-header {
    box-sizing: border-box;
    height: 34px;
    line-height: 34px;
    text-align: center;
    overflow: hidden;
  }

  .#{$namespace}-btn-icon-left,
  .#{$namespace}-btn-icon-double-left {
    float: left;
  }
  .#{$namespace}-btn-icon-right,
  .#{$namespace}-btn-icon-double-right {
    float: right;
  }

  .#{$namespace}-calendar-header-label {
    font-size: 14px;
  }

  .#{$namespace}-calendar-decade-separator {
    margin: 0 2px;
    &:after {
      content: '~';
    }
  }

  .#{$namespace}-calendar-content {
    position: relative;
    height: 224px;
    box-sizing: border-box;
    .cell {
      cursor: pointer;
      &:hover {
        color: $calendar-hover-color;
        background-color: $calendar-hover-background-color;
      }
      &.active {
        color: $calendar-active-color;
        background-color: $calendar-active-background-color;
      }
      &.in-range,
      &.hover-in-range {
        color: $calendar-in-range-color;
        background-color: $calendar-in-range-background-color;
      }
      &.disabled {
        cursor: not-allowed;
        color: $disabled-color;
        background-color: $disabled-background-color;
      }
    }
  }

  .#{$namespace}-calendar-week-mode {
    .#{$namespace}-date-row {
      cursor: pointer;
      &:hover {
        background-color: $calendar-hover-background-color;
      }
      &.#{$namespace}-active-week {
        background-color: $calendar-in-range-background-color;
      }
      .cell {
        &:hover {
          color: inherit;
          background-color: transparent;
        }
        &.active {
          color: inherit;
          background-color: transparent;
        }
      }
    }
  }

  .#{$namespace}-week-number {
    opacity: 0.5;
  }

  .#{$namespace}-table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;

    th {
      padding: 0;
      font-weight: 500;
      vertical-align: middle;
    }
    td {
      padding: 0;
      vertical-align: middle;
    }
  }

  .#{$namespace}-table-date {
    td,
    th {
      height: 32px;
      font-size: 12px;
    }

    .today {
      color: $today-color;
    }
    .cell.not-current-month {
      color: #ccc;
      background: none; // cover the in-range style
    }
  }

  .#{$namespace}-time {
    flex: 1;
    width: 224px;
    background: #fff;
    & + & {
      border-left: 1px solid $border-color;
    }
  }
  .#{$namespace}-calendar-time {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .#{$namespace}-time-header {
    @extend .#{$namespace}-calendar-header;
    border-bottom: 1px solid $border-color;
  }

  .#{$namespace}-time-content {
    height: 224px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .#{$namespace}-time-columns {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .#{$namespace}-time-column {
    flex: 1;
    position: relative;
    border-left: 1px solid $border-color;
    text-align: center;

    &:first-child {
      border-left: 0;
    }
    .#{$namespace}-time-list {
      margin: 0;
      padding: 0;
      list-style: none;
      &::after {
        content: '';
        display: block;
        height: 32 * 6px;
      }
    }
    .#{$namespace}-time-item {
      cursor: pointer;
      font-size: 12px;
      height: 32px;
      line-height: 32px;
      &:hover {
        color: $time-hover-color;
        background-color: $time-hover-background-color;
      }
      &.active {
        color: $time-active-color;
        background-color: $time-active-background-color;
        font-weight: 700;
      }
      &.disabled {
        cursor: not-allowed;
        color: $disabled-color;
        background-color: $disabled-background-color;
      }
    }
  }

  .#{$namespace}-time-option {
    cursor: pointer;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      color: $time-hover-color;
      background-color: $time-hover-background-color;
    }
    &.active {
      color: $time-active-color;
      background-color: $time-active-background-color;
      font-weight: 700;
    }
    &.disabled {
      cursor: not-allowed;
      color: $disabled-color;
      background-color: $disabled-background-color;
    }
  }
</style>
